.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #282c34;
    color: #fff;
  }
  
  .error-icon {
    font-size: 5rem;
    margin-bottom: 20px;
    animation: float 3s infinite ease-in-out;
  }
  
  .error-title {
    font-size: 8rem;
    margin: 0;
  }
  
  .error-text {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .error-message {
    font-size: 1.25rem;
    margin-bottom: 20px;
    color: #ffcc00;
  }
  
  .error-home-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.25rem;
    background-color: #ff6347;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .error-home-link:hover {
    background-color: #ff4500;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  