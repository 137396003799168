/* Container for the entire tree */

.tree-container {
  max-width: 100%;
  /* max-height: 80vh; Limit height to 80% of the viewport height */
  overflow-x: auto; /* Horizontal scrolling */
  overflow-y: auto; /* Vertical scrolling */
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 10px;
  box-sizing: border-box;
} 
/* Family member styling */
.family-member {
  overflow-x: auto; /* Horizontal scrolling */
  overflow-y: auto; /* Vertical scrolling */

  border: 2px solid #007bff;
  margin: 20px;
  padding: 10px;
  text-align: center;
  max-width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  margin-bottom: 10px;
  transition: width 0.3s ease;
}

/* Children container */
.children-container {
  display: grid;
  grid-template-columns: repeat(auto-fit); /* Responsive columns */
  justify-items: center;
  position: relative;
  margin-top: 20px;
 color: deepskyblue;
}

/* Child member styling */
.child-member {
   display: flex;
  flex-direction: column;
  align-items: center; /* Center child member */
  position: relative;
   
}

/* Connecting lines using pseudo-elements */
.child-member::before {
 
  content: '';
  width: 2px;
  background-color: #007bff;
  position: absolute;
  top: -20px; /* Position above the child box */
  left: 50%;
  transform: translateX(-50%); /* Center the line */
  height: 20px; /* Height of the line */
}

.selected {
  background-color: #e4e7e9; /* Highlight selected member */
  color: rgb(240, 12, 12); /* Change text color */
  border-color: #0056b3;
}

/* Parent of the selected member styling */
.parent {
  background-color: #f0f0f0; /* Different color for parent */
  border-color: #ff7e06;
  color: rgb(12, 46, 240); /* Change text color */
}



  button {
    width: 100%; /* Full-width buttons */
    padding: 10px;
    font-size: 1em;
    margin-top: 10px; /* Add some space above buttons */
  }

  @media (max-width: 768px) {
    .family-member {
      display: block; /* Stack members vertically */
     /* width: 100%;  Full width */
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }}
