.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px;
    max-width: 300px; 
    text-align: center;
    
  }
  
  .card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    /* color: #333; */
  }
  
  .card-content {
    font-size: 1rem;
    color: #666;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the cards */
    align-items: stretch; /* Ensures all cards have the same height if one grows */
    padding: 10px;
  }
  
  .card-container .card {
    flex: 1 1 auto; /* Default: responsive width */
    margin: 10px;
    box-sizing: border-box;
  }
  
 
  